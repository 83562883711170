import {
  AdditiveBlending,
  BackSide,
  Color,
  DoubleSide,
  Fog,
  FrontSide,
  Mesh,
  MeshBasicMaterial,
  MeshStandardMaterial,
  Object3D,
  PCFSoftShadowMap,
  Scene,
  sRGBEncoding,
  Vector3,
  WebGLRenderer,
} from "three";

import Stats from "three/examples/jsm/libs/stats.module.js";
import { GLTF, GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import { degToRad } from "three/src/math/MathUtils";
import {
  DEFAULT_FLOOR,
  DEFAULT_PLAN,
} from "../../components/ApTopMenu/Items/FloorMiniMap";
import DvEdificeCamera from "../dynve/DvEdificeCamera";
import DvLightManager from "../dynve/DvLightManager";
import DvMeasure from "../dynve/DvMeasure";
import ConfigManager, { ConfigOptions } from "../helpers/ConfigManager";
import EEventBus from "../helpers/EEventBus";
import { EventBus } from "../helpers/EventBus";
import { filterChildMeshs } from "../helpers/FilterChild";
import overrideMaterial from "../helpers/MaterialOverrider";
import DvSceneBase from "./DvSceneBase";
import ProjectData from "./ProjectData.json";

export default class DvEdificeInternal extends DvSceneBase {
  private scene: Scene;
  private renderer: WebGLRenderer;
  private camera: DvEdificeCamera;

  private model?: GLTF;

  private stats?: Stats;

  private furnitureMeshs = new Array<Object3D>();
  private measures = new Array<DvMeasure>();
  private ghostRoad?: Object3D;
  private ghostBottomFloor?: Object3D;

  constructor(canvas: HTMLCanvasElement) {
    super();

    this.scene = new Scene();
    this.scene.background = new Color().setHSL(0.6, 0, 1);
    this.scene.fog = new Fog(this.scene.background, 100, 1000);

    this.renderer = new WebGLRenderer({
      canvas,
      antialias: true,
    });
    this.renderer.setPixelRatio(
      ConfigManager.getNumber(
        ConfigOptions.PIXEL_RATIO,
        window.devicePixelRatio
      )
    );
    this.renderer.shadowMap.type = PCFSoftShadowMap; //THREE.BasicShadowMap | THREE.PCFShadowMap |  THREE.VSMShadowMap | THREE.PCFSoftShadowMap
    this.renderer.shadowMap.enabled = true;
    this.renderer.outputEncoding = sRGBEncoding;

    //set initial renderer size and watch window resize
    this.renderer.setSize(window.innerWidth, window.innerHeight);
    window.addEventListener("resize", () => {
      this.renderer.setSize(window.innerWidth, window.innerHeight);
      this.camera.aspect = window.innerWidth / window.innerHeight;
      this.camera.updateProjectionMatrix();
    });

    this.camera = new DvEdificeCamera(this.renderer, {
      minDistance: 10,
      maxDistance: 30,
    });

    this.loadModel("/assets/scenes/faax_01-edsaoluiz_v2_internal.glb");

    // new DvSkyDome(this.scene);
    const lm = new DvLightManager(this.scene, {
      shadowBoxSize: 35,
      shadowBias: -0.00025,
    });

    //temp stats
    if (ConfigManager.getBoolean(ConfigOptions.SHOW_STATS)) {
      this.stats = Stats();
      document.body.appendChild(this.stats.dom);
    }

    //plan change event
    EventBus.getInstance().register(
      EEventBus.SET_APARTMENT_PLAN,
      (plan: number) => this.setPlan(plan)
    );

    //register for furniture toggle event
    EventBus.getInstance().register(EEventBus.TOGGLE_FURNITURE, () => {
      this.furnitureMeshs.forEach((mesh) => (mesh.visible = !mesh.visible));
    });

    this.update();

    console.log("DynVE Initialized!");
  }

  public dispose() {
    //forcing context loss seems to work better to avoid slowdown after url switching
    this.renderer.forceContextLoss();

    // this.renderer.dispose();
  }

  loadModel(filename: string) {
    const loader = new GLTFLoader();

    loader.load(
      filename,
      (gltf) => {
        this.model = gltf;

        // console.log(gltf);

        gltf.scene.traverse((child) => {
          if (child.name?.includes("Internal_Walls")) {
            filterChildMeshs(child).forEach((mesh) => (mesh.castShadow = true));
          }

          if (child.name?.includes("InvisibleShadowCaster")) {
            filterChildMeshs(child).forEach((mesh) => {
              mesh.material = new MeshBasicMaterial({
                color: 0x000000,
                transparent: true,
                blending: AdditiveBlending,
                side: DoubleSide,
              });

              mesh.castShadow = true;
            });
          }

          child.receiveShadow = true;

          //override glass material instances
          if (child.type === "Mesh") {
            const mesh = child as Mesh;
            const meshMat = mesh.material as MeshStandardMaterial;
            if (meshMat.name === "Generic_Glass_1") {
              overrideMaterial(mesh);

              //additional overrides for internal glass
              mesh.material = (mesh.material as MeshStandardMaterial).clone();
              (mesh.material as MeshStandardMaterial).transparent = true;
              (mesh.material as MeshStandardMaterial).opacity = 0.5;
            }
          }

          // register furniture meshs
          if (child.name.startsWith("Mob_")) {
            this.furnitureMeshs.push(child);
          }

          // register measures
          if (child.name.startsWith("Measure_")) {
            this.measures.push(new DvMeasure(this.scene, child as Mesh));
          }

          // configure ghosts
          if (child.name?.includes("Ghost_")) {
            if (child.name === "Ghost_Road") {
              this.ghostRoad = child;
            } else if (child.name === "Ghost_BottomFloor") {
              this.ghostBottomFloor = child;
            }

            filterChildMeshs(child).forEach((mesh) => {
              mesh.receiveShadow = true;
              mesh.castShadow = true;
            });
          }
          //   if (mesh.name.startsWith("Ghost_")) {
          //     // mesh.material = new MeshStandardMaterial({
          //     //   // color: "black", //0xffffff,
          //     //   color: "grey",
          //     //   roughness: 1,
          //     //   metalness: 0,
          //     //   // opacity: 0.2,
          //     //   // transparent: true,
          //     //   side: DoubleSide,
          //     // });

          //     console.log(mesh.name);
          //     if (mesh.name == "Ghost_Road") {
          //       this.ghostRoad = mesh;
          //     } else if (mesh.name == "Ghost_BottomFloor") {
          //       this.ghostBottomFloor = mesh;
          //     }
          //   }
          // }
        });

        this.measures.forEach((measure) => measure.setup());

        this.scene.add(gltf.scene);

        // temp: preserve road orientation besides plan changes
        gltf.scene.remove(this.ghostRoad!);
        this.scene.add(this.ghostRoad!);
        this.ghostRoad!.rotation.set(
          0,
          degToRad(ProjectData.edificeOrientation),
          0
        );

        //------ configure road and bottom floor
        //TODO: ISOLATE in a METHOD!! SET MIN_FLOOR and MAX_FLOOR in projectData json
        const GHOST_FLOOR_HEIGHT = 3.1;

        const setFloorPosition = (floor: number) => {
          this.ghostRoad!.position.y =
            -GHOST_FLOOR_HEIGHT * floor + GHOST_FLOOR_HEIGHT - 0.05;
          this.ghostBottomFloor!.scale.y = floor;
        };

        EventBus.getInstance().register(
          EEventBus.SET_APARTMENT_FLOOR,
          (floor: number) => {
            setFloorPosition(floor);
          }
        );
        //------ END TODO

        setFloorPosition(DEFAULT_FLOOR);
        this.setPlan(DEFAULT_PLAN);

        this.setCameraInitialPos();

        // dispatch finish event
        this.onCompleteLoading();
      },
      undefined,
      (error) => {
        console.error(error);
      }
    );
  }

  setCameraInitialPos() {
    const target = this.camera.getTarget();
    this.camera.position.set(target.x, target.y + 5, target.z - 20);
    this.camera.lookAt(target.x, target.y, target.z);
  }

  setPlan(plan: number) {
    const { edificeOrientation } = ProjectData;
    // this.scene.rotation.y = degToRad(edificeOrientation);

    // DEMO VALUES
    const transform: any = {
      //frente lado dir
      0: {
        rot: 180,
        scale: { x: 1, y: 1, z: 1 },
        cam: { x: 0, y: 0, z: -10 },
      },
      //frente lado esq
      2: { rot: 0, scale: { x: 1, y: 1, z: -1 }, cam: { x: -5, y: 0, z: -10 } },
      //fundos lado dir
      1: {
        rot: 180,
        scale: { x: 1, y: 1, z: -1 },
        cam: { x: 5, y: 0, z: 10 },
      },
      //fundos lado esq
      3: { rot: 0, scale: { x: 1, y: 1, z: 1 }, cam: { x: 0, y: 0, z: 10 } },
    };

    const tplan = transform[plan];

    this.model?.scene.rotation.set(
      0,
      degToRad(tplan.rot + edificeOrientation),
      0
    );

    this.camera.setTarget(tplan.cam.x, tplan.cam.y, tplan.cam.z);

    const { x, y, z } = tplan.scale;
    this.model?.scene.scale.set(x, y, z);
  }

  // ******************* RENDER LOOP ******************* //
  update() {
    this.renderer.render(this.scene, this.camera);
    requestAnimationFrame(this.update.bind(this));

    this.stats?.update();
  }
}
